.fontAwesomePadding {
	padding-right: 20px;
}

.fa-check {
	color: green !important;
}

.fa-x {
	color: red !important;
}

.password-strength {
	border: 1px solid;
}

.password-strength-meter {
	height: 30px;
	color: #fff;
	display: flex;
	justify-content: left;
	align-items: center;

	p {
		padding-left: 5px;
	}
}

.p-viewer {
	margin-right: 10px;
}

.p-validate {
	margin-right: 45px;
}

.p-viewer,
.p-validate {
	float: right;
	margin-top: -30px;
	position: relative;
	z-index: 1;
	cursor: pointer;
}
