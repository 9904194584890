.zi-sidebar-links {
}

.zi-sidebar-links > li > a {
	padding: var(--pf-global--spacer--xs) 0 var(--pf-global--spacer--xs)
		var(--pf-c-page__main-section--PaddingLeft);
	color: var(--pf-global--BackgroundColor--dark-100);
	text-decoration: none;
	display: block;
}

.zi-sidebar-links > li > a:hover {
	background-color: var(--pf-global--primary-color--100);
	cursor: pointer;
	color: var(--pf-global--icon--Color--dark);
	text-decoration: none;
	color: white;
}

.zi-sidebar-links > li > a.active {
	background-color: var(--pf-global--primary-color--100);
	color: white;
}

.zi-sidebar > .pf-c-sidebar__main {
	align-items: stretch;
	height: 100%;
}
