body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
	overflow-y: scroll;
}

#root {
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	--pf-global--BorderRadius--sm: 6px;
	--pf-global--primary-color--100: #667080;
	/*--pf-global--spacer--form-element: 0.75rem;*/
	/*--pf-global--spacer--sm:*/
	--pf-global--FontFamily--sans-serif: 'Inter', 'RedHatText', 'Overpass', overpass, helvetica,
		arial, sans-serif;
}

.pf-c-dropdown__toggle {
	--pf-c-dropdown__toggle--PaddingTop: var(--pf-global--spacer--form-element);
	--pf-c-dropdown__toggle--PaddingRight: var(--pf-global--spacer--sm);
	--pf-c-dropdown__toggle--PaddingBottom: var(--pf-global--spacer--form-element);
	--pf-c-dropdown__toggle--PaddingLeft: var(--pf-global--spacer--sm);
	--pf-c-dropdown__toggle--FontWeight: 700;
	padding: 12px 16px 12px 24px;
}

/*.pf-c-alert-group.pf-m-toast {*/
/*	position: fixed;*/
/*	bottom: var(--pf-c-alert-group--m-toast--Top);*/
/*	left: var(--pf-c-alert-group--m-toast--Right);*/
/*	z-index: var(--pf-c-alert-group--m-toast--ZIndex);*/
/*	width: calc(100% - var(--pf-c-alert-group--m-toast--Right) * 2);*/
/*	max-width: var(--pf-c-alert-group--m-toast--MaxWidth);*/
/*	top: inherit;*/
/*}*/
